import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import Icon from "react-icons-kit";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Button from "common/components/Button";
import Container from "common/components/UI/Container";
import GlideCarousel from "common/components/GlideCarousel";
import GlideSlide from "common/components/GlideCarousel/glideSlide";

import { Grid } from "@mui/material";

import PricingTable, {
  PricingHead,
  PricingPrice,
  PricingButton,
  PricingList,
  ListItem,
  PricingButtonWrapper,
} from "./pricing.style";

import { checkmark } from "react-icons-kit/icomoon/checkmark";

const PricingSection = (
  sectionWrapper = {
    as: "section",
    pt: ["100px", "100px", "100px", "140px", "160px"],
    pb: ["60px", "80px", "80px", "100px"],
  },
  row = {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    alignItems: "center",
  },
  secTitleWrapper = {
    mb: ["0px", "10px"],
  },
  secText = {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#2aa275",
    mb: "5px",
  },
  secHeading = {
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "500",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
    lineHeight: "1.67",
  },
  nameStyle = {
    fontSize: ["20px", "20px", "22px", "22px", "22px"],
    fontWeight: "500",
    color: "headingColor",
    // letterSpacing: "-0.025em",
    textAlign: "center",
    mb: "5px",
  },
  descriptionStyle = {
    fontSize: ["15px", "16px", "16px", "16px", "16px"],
    color: "textColor",
    lineHeight: "1",
    textAlign: "center",
    mb: "0",
  },
  priceStyle = {
    as: "span",
    display: "block",
    fontSize: ["20px", "20px", "40px", "40px", "40px"],
    color: "headingColor",
    textAlign: "center",
    mb: "0",
    letterSpacing: "-0.025em",
  },
  priceLabelStyle = {
    fontSize: ["10px", "10px", "20px", "20px", "20px"],
    color: "textColor",
    lineHeight: "1",
    textAlign: "center",
    mb: "0",
  },
  listContentStyle = {
    fontSize: ["12px", "12px", "16px", "16px", "16px"],
    color: "textColor",
    mb: "0",
  }
) => {
  const Data = useStaticQuery(graphql`
    query {
      landingJson {
        MONTHLY_PRICING_TABLE {
          name
          description
          price
          priceLabel
          listItems {
            content
          }
        }
      }
    }
  `);

  const [state, setState] = useState({
    data: Data.landingJson.MONTHLY_PRICING_TABLE,
    active: true,
  });

  const data = state.data;
  const activeStatus = state.active;

  return (
    <Box {...sectionWrapper} id="pricing_section">
      <Container fullWidth>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="Precios" />
          <Heading {...secHeading} content="Paquetes de exámenes" />
        </Box>
        <Box {...row}>
          <Grid container spacing={2} direction="row">
            {data.map((pricingTable, index) => (
              <Grid item md={3} xs={6} key={`pricing-table-${index}`}>
                <PricingTable
                  freePlan={pricingTable.freePlan}
                  className="pricing_table"
                >
                  <PricingHead>
                    <Heading content={pricingTable.name} {...nameStyle} />
                    {/* <Text
                      content={pricingTable.description}
                      {...descriptionStyle}
                    /> */}
                  </PricingHead>
                  <PricingPrice>
                    <Text content={pricingTable.price} {...priceStyle} />
                    <Text
                      content={pricingTable.priceLabel}
                      {...priceLabelStyle}
                    />
                  </PricingPrice>
                  {/* <PricingButton>
                      <a href={pricingTable.url}>
                        {pricingTable.freePlan ? (
                          <Button
                            title={pricingTable.buttonLabel}
                            {...buttonStyle}
                          />
                        ) : (
                          <Button
                            title={pricingTable.buttonLabel}
                            {...buttonFillStyle}
                          />
                        )}
                      </a>
                    </PricingButton> */}
                  <PricingList>
                    {pricingTable.listItems.map((item, index) => (
                      <ListItem key={`pricing-table-list-${index}`}>
                        <Icon
                          icon={checkmark}
                          className="price_list_icon"
                          size={13}
                        />
                        <Text content={item.content} {...listContentStyle} />
                      </ListItem>
                    ))}
                  </PricingList>
                </PricingTable>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default PricingSection;
