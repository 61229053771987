import React, { Fragment } from "react";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import Sticky from "react-stickynode";
import PricingSection from "containers/Laboratorio/Pricing";
import { ResetCSS } from "common/assets/css/style";
import Navbar from "containers/Landing/Navbar";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { saasModernTheme } from "common/theme/saasModern";
import SEO from "components/seo";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/Landing/sassModern.style";
import BannerSection from "containers/Laboratorio/Banner";
import Footer from "containers/Landing/Footer";
import { theme } from "../theme";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SEO
          title="Laboratorio | MiDiagnóstico"
          description="Descubre nuestros servicios de laboratorio a domicilio y presencial. Puedes agendar directamente"
        />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection
            contentWrapper={{
              marginBottom: 100,
            }}
          />
          <PricingSection as="section" pt={[]} pb={[]} />
          <Footer />
        </ContentWrapper>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
